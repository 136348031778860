/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { HallsListResponseDTO } from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class Halls<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Halls, Halls
   * @name GetHallsApiHallsGet
   * @summary Get Halls
   * @request GET:/api/halls
   * @response `200` `(HallsListResponseDTO)[]` Successful Response
   */
  getHallsApiHallsGet = (params: RequestParams = {}) =>
    this.request<HallsListResponseDTO[], any>({
      path: `/api/halls`,
      method: "GET",
      format: "json",
      ...params,
    });
}
