/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  HTTPValidationError,
  TimepadOrderResponseDTO,
  TimepadOrderUpdateParams,
  WishlistCreateDTO,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Users<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Users, Users
   * @name GetUserAvatarApiUsersPhotosGet
   * @summary Get User Avatar
   * @request GET:/api/users/photos
   * @response `200` `any` Successful Response
   */
  getUserAvatarApiUsersPhotosGet = (params: RequestParams = {}) =>
    this.request<any, any>({
      path: `/api/users/photos`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Users, Users
   * @name AddToWishlistApiUsersWishlistPost
   * @summary Add To Wishlist
   * @request POST:/api/users/wishlist
   * @response `204` `void` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  addToWishlistApiUsersWishlistPost = (data: WishlistCreateDTO, params: RequestParams = {}) =>
    this.request<void, HTTPValidationError>({
      path: `/api/users/wishlist`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Users, Users
   * @name RemoveFromWishlistApiUsersWishlistLectureIdDelete
   * @summary Remove From Wishlist
   * @request DELETE:/api/users/wishlist/{lecture_id}
   * @response `204` `void` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  removeFromWishlistApiUsersWishlistLectureIdDelete = (lectureId: number, params: RequestParams = {}) =>
    this.request<void, HTTPValidationError>({
      path: `/api/users/wishlist/${lectureId}`,
      method: "DELETE",
      ...params,
    });
  /**
   * No description
   *
   * @tags Users, Users
   * @name CreateTimepadOrderApiUsersTimepadOrderPost
   * @summary Create Timepad Order
   * @request POST:/api/users/timepad_order
   * @response `200` `string` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  createTimepadOrderApiUsersTimepadOrderPost = (data: TimepadOrderUpdateParams, params: RequestParams = {}) =>
    this.request<string, HTTPValidationError>({
      path: `/api/users/timepad_order`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Users, Users
   * @name GetTimepadOrdersApiUsersTimepadOrdersGet
   * @summary Get Timepad Orders
   * @request GET:/api/users/timepad_orders
   * @response `200` `(TimepadOrderResponseDTO)[]` Successful Response
   */
  getTimepadOrdersApiUsersTimepadOrdersGet = (params: RequestParams = {}) =>
    this.request<TimepadOrderResponseDTO[], any>({
      path: `/api/users/timepad_orders`,
      method: "GET",
      format: "json",
      ...params,
    });
}
