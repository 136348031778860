import React from "react";
import { CopyrightStrictDTO } from "@/api/gen/data-contracts";
import useFetch from "@/shared/hooks/useFetch";
import api from "@/api/api";


interface ICopyrightsContext {
    copyrights: CopyrightStrictDTO;
    // setCopyrights: React.Dispatch<React.SetStateAction<CopyrightStrictDTO>>
}

export const CopyrightsContext = React.createContext<ICopyrightsContext>({} as ICopyrightsContext);

/**
 * Контекст для управления светлой и темной темами
 * @param param0 
 * @returns 
 */
export default function CopyrightsContextProvider({ children }: any) {
    const {data} = useFetch(
        ()=>api.copyright.getCopyrightsDetailApiCopyrightsGet(),
        {} as CopyrightStrictDTO,
        []
    )
    const providerInterface = React.useMemo(() => ({
        copyrights: data
    }), [data])

    if (!data?.main_page) return null
    return (
        <CopyrightsContext.Provider value={providerInterface}>
            {children}
        </CopyrightsContext.Provider>
    )
}