/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  HTTPValidationError,
  ServerApiSpeakersClientDtoSpeakersListResponseDTO,
  ServerApiSpeakersClientDtoSpeakersResponseDTO,
} from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class Speakers<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Speakers, Speakers
   * @name GetSpeakersApiSpeakersGet
   * @summary Get Speakers
   * @request GET:/api/speakers
   * @response `200` `(ServerApiSpeakersClientDtoSpeakersListResponseDTO)[]` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  getSpeakersApiSpeakersGet = (
    query?: {
      /**
       * Tags
       * @default []
       */
      tags?: number[] | null;
      /** Hall Ref */
      hall_ref?: number | null;
      /** Lecture Date */
      lecture_date?: string | null;
    },
    params: RequestParams = {},
  ) =>
    this.request<ServerApiSpeakersClientDtoSpeakersListResponseDTO[], HTTPValidationError>({
      path: `/api/speakers`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Speakers, Speakers
   * @name GetSpeakersDetailApiSpeakersIdGet
   * @summary Get Speakers Detail
   * @request GET:/api/speakers/{_id}
   * @response `200` `ServerApiSpeakersClientDtoSpeakersResponseDTO` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  getSpeakersDetailApiSpeakersIdGet = (id: number, params: RequestParams = {}) =>
    this.request<ServerApiSpeakersClientDtoSpeakersResponseDTO, HTTPValidationError>({
      path: `/api/speakers/${id}`,
      method: "GET",
      format: "json",
      ...params,
    });
}
