/** @format */

import { ApiConfig } from './gen/http-client';
import { Copyrights } from './gen/Copyrights';
import { Lectures } from './gen/Lectures';
import { Organizers } from './gen/Organizers';
import { Partners } from './gen/Partners';
import { Speakers } from './gen/Speakers';
import { Uploads } from './gen/Uploads';
import { Tags } from './gen/Tags';
import { Users } from './gen/Users';
import { CONSTANTS } from '@/app/config';
import { Halls } from './gen/Halls';
import { Appeals } from './gen/Appeals';
import { Userprizes } from './gen/Userprizes';

const apiStore = (apiConfig?: ApiConfig<unknown>) => ({
	copyright: new Copyrights(apiConfig),
	lectures: new Lectures(apiConfig),
	organizers: new Organizers(apiConfig),
	partners: new Partners(apiConfig),
	speakers: new Speakers(apiConfig),
	uploads: new Uploads(apiConfig),
	tags: new Tags(apiConfig),
	users: new Users(apiConfig),
	usersPrises: new Userprizes(apiConfig),
	halls: new Halls(apiConfig),
	appeals: new Appeals(apiConfig),
});

const api = apiStore({
	baseUrl: CONSTANTS.API_HOST,
});

export default api;
