/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { HTTPValidationError, ServerApiLecturesClientDtoLecturesListResponseDTO } from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class Lectures<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Lectures, Lectures
   * @name GetLecturesApiLecturesGet
   * @summary Get Lectures
   * @request GET:/api/lectures
   * @response `200` `(ServerApiLecturesClientDtoLecturesListResponseDTO)[]` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  getLecturesApiLecturesGet = (
    query?: {
      /**
       * Tags
       * @default []
       */
      tags?: number[] | null;
      /** Start Date */
      start_date?: string | null;
      /** Speakers */
      speakers?: number[] | null;
      /**
       * Only Wishlist
       * @default false
       */
      only_wishlist?: boolean;
    },
    params: RequestParams = {},
  ) =>
    this.request<ServerApiLecturesClientDtoLecturesListResponseDTO[], HTTPValidationError>({
      path: `/api/lectures`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
}
