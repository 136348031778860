/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { TagsListResponseDTO } from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class Tags<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Tags, Tags
   * @name GetTagsApiTagsGet
   * @summary Get Tags
   * @request GET:/api/tags
   * @response `200` `(TagsListResponseDTO)[]` Successful Response
   */
  getTagsApiTagsGet = (params: RequestParams = {}) =>
    this.request<TagsListResponseDTO[], any>({
      path: `/api/tags`,
      method: "GET",
      format: "json",
      ...params,
    });
}
