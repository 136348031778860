/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { AppealCreateDTO, HTTPValidationError } from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Appeals<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Appeals, Appeals
   * @name CreateAppealApiAppealsPost
   * @summary Create Appeal
   * @request POST:/api/appeals
   * @response `204` `void` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  createAppealApiAppealsPost = (data: AppealCreateDTO, params: RequestParams = {}) =>
    this.request<void, HTTPValidationError>({
      path: `/api/appeals`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
}
