/** @format */

import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import useTG from '@/shared/hooks/useTG';
import '@/shared/styles/blank.scss';
import '@/shared/styles/fonts-unbounded.scss';
import '@/shared/styles/fonts-monteserrat.scss';
import '@/shared/styles/main.scss';
import '@/shared/styles/animations.scss';
import { ProviderStore } from './context/ProviderStore';

const Programm = lazy(() => import('@/pages/program'));
const WishList = lazy(() => import('@/pages/program/WishList'));
const SpeakerPage = lazy(() => import('@/pages/speakers'));
const SpeakersListPage = lazy(() => import('@/pages/speakers/List'));
const OrganizersPage = lazy(() => import('@/pages/organizers'));
const MapPage = lazy(() => import('@/pages/map'));
const StandPage = lazy(() => import('@/pages/stand'));
const FAQPage = lazy(() => import('@/pages/faq'));
const PartnersPage = lazy(() => import('@/pages/partners'));
const ProfilePage = lazy(() => import('@/pages/profile'));
const TicketForm = lazy(() => import('@/pages/profile/TicketForm'));
const Index = lazy(() => import('@/pages/index/Index'));
const Welcome = lazy(() => import('@/pages/welcome'));
const BuyTicketPage = lazy(() => import('@/pages/buy'));

function AppRouter() {
	const { WebApp } = useTG({ requestWriteAccess: true });

	React.useEffect(() => {
		WebApp?.expand();
	}, []);

	return (
		<Suspense fallback={null}>
			<ProviderStore>
				<Router>
					<Routes>
						<Route
							path='/'
							element={<Index />}
						/>
						{/* <Route
							path='/buy'
							element={<BuyTicketPage />}
						/> */}
						<Route
							path='/welcome'
							element={<Welcome />}
						/>
						<Route
							path='/program'
							element={<Programm />}
						/>
						<Route
							path='/wishlist'
							element={<WishList />}
						/>
						<Route
							path='/speakers'
							element={<SpeakersListPage />}
						/>
						<Route
							path='/speakers/:id'
							element={<SpeakerPage />}
						/>
						<Route
							path='/organizers'
							element={<OrganizersPage />}
						/>
						<Route
							path='/map'
							element={<MapPage />}
						/>
						<Route
							path='/stand'
							element={<StandPage />}
						/>
						<Route
							path='/faq'
							element={<FAQPage />}
						/>
						<Route
							path='/partners'
							element={<PartnersPage />}
						/>
						<Route
							path='/profile'
							element={<ProfilePage />}
						/>
					</Routes>
				</Router>
			</ProviderStore>
		</Suspense>
	);
}

export default AppRouter;
