import React from "react";
import CopyrightsContextProvider from "./CopyrightsContextProvider";

/**
 * Точка включения всех необходимых глобальных контекстов
 * @param param0 
 * @returns 
 */
export function ProviderStore({ children }: any) {
    return (
        <CopyrightsContextProvider>
            {children}
        </CopyrightsContextProvider>
    )
}