/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  BodyUploadCopyrightMainPageFileApiUploadsCopyrightMainPageJsonPathPost,
  BodyUploadFileApiUploadsGenericSourceInstanceIdPost,
  HTTPValidationError,
  SourceType,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Uploads<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Uploads
   * @name UploadFileApiUploadsGenericSourceInstanceIdPost
   * @summary Upload File
   * @request POST:/api/uploads/generic/{source}/{instance_id}
   * @response `204` `void` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  uploadFileApiUploadsGenericSourceInstanceIdPost = (
    source: SourceType,
    instanceId: number,
    data: BodyUploadFileApiUploadsGenericSourceInstanceIdPost,
    params: RequestParams = {},
  ) =>
    this.request<void, HTTPValidationError>({
      path: `/api/uploads/generic/${source}/${instanceId}`,
      method: "POST",
      body: data,
      type: ContentType.FormData,
      ...params,
    });
  /**
   * No description
   *
   * @tags Uploads
   * @name UploadCopyrightMainPageFileApiUploadsCopyrightMainPageJsonPathPost
   * @summary Upload Copyright Main Page File
   * @request POST:/api/uploads/copyright/main_page/{json_path}
   * @response `204` `void` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  uploadCopyrightMainPageFileApiUploadsCopyrightMainPageJsonPathPost = (
    jsonPath: string,
    data: BodyUploadCopyrightMainPageFileApiUploadsCopyrightMainPageJsonPathPost,
    params: RequestParams = {},
  ) =>
    this.request<void, HTTPValidationError>({
      path: `/api/uploads/copyright/main_page/${jsonPath}`,
      method: "POST",
      body: data,
      type: ContentType.FormData,
      ...params,
    });
  /**
   * No description
   *
   * @tags Uploads
   * @name GetMediaFileApiUploadsFilePathGet
   * @summary Get Media File
   * @request GET:/api/uploads/{file_path}
   * @response `200` `void` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  getMediaFileApiUploadsFilePathGet = (filePath: string, params: RequestParams = {}) =>
    this.request<void, HTTPValidationError>({
      path: `/api/uploads/${filePath}`,
      method: "GET",
      ...params,
    });
}
